@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

html {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  min-height: 100%;
  display: flex;
  overflow: hidden;
}