.event-card {
    display: flex;
    flex-direction: column;
    width: 340px;
    margin-top: 14px;
    margin-bottom: 14px;
    padding: 6px 9px 5px 13px;
    user-select: none;
}
.event-card:focus {
    outline: none;
    border: #5DC2C9;
    border-width: 1px;
    border-style: solid;
}
.event-card-focus {
    border: #655BCD;
    border-width: 2px;
    border-style: solid;
    width: 340px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 4px 9px 5px 13px;
    user-select: none;
}

.hover-card {
    margin-top: 0px;
    margin-bottom: 0px;
}

.event-card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
}
.event-card-bottom {
    display: flex;
}
.event-tags {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 9px;
}
.event-image {
    object-fit: cover;
    height: 99px;
    width: 99px;
}
.event-image-wrapper {
}
.event-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3C4858;
    text-align: left;
}
.event-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 7px;
    text-align: start;
    line-height: 1.5;
    font-size: 11px;
    min-height: 48px;
}
.expand-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 12px;     /* fallback */
    max-height: 38px;      /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;

}
.event-divider {
    width: 80%;
    border-top: 1px solid #cccccc7d;
}
.event-duration-tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.event-taglist {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.event-icon {
    height: 16px;
    margin-right: 4px;
}
.event-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 20px;
}
.event-source > a {
    color: #2080FF;
}
.event-location {
    display: flex;
}
.event-loc-pin {
    height: 11px;
}
.event-loc-name {
    margin-left: 6px;
    color: #9597A1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}