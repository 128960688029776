@import "./variables";

.new-event-card {
    display: flex;
    flex-direction: row;
    padding: 25px;
    justify-content: center;
}
.inspiration-link {
    min-width: 550px;
    border-radius: 5px;
}

.new-event-card-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.new-event-list {
    overflow: scroll;
}
.less-round {
    border-radius: 5px;
}
.btn-round {
    border-radius: 5px;
    background: #5DC2C9;
    color: white;
}
.vl {
    border-left: 1px solid #E0E0E0;
    margin-left: 20px;
    margin-right: 20px;
    height: 40px;
}
.nav-actions {
  display: flex;
  flex-direction: row;
}
.nav-icon {
  width: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 5px;
  span {
    margin-top: 3px;
  }
}
.nav-icon:hover, .nav-icon.selected {
  background: $purpleColor;
  color: white;
  svg {
    fill: white;
  }
}
.empty-event-btn {
    padding: 5px;
    background-color: #4e72d5 !important;
    font-size: 16px;
    color: white;
    border-radius: 5px !important;
    font-weight: 500;
    text-align: center;
}
.new-event-add {
    display: flex;
    align-items: center;
    justify-content: center;
}
.new-event-details {
    width: 100%;
    padding-left: 9px;
    text-align: start;
    line-height: 1.5;
    font-size: 13px;
}
.edit-event-dialog {
    padding: 16px;
}
.edit-event-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.edit-event-middle {
    display: flex;
    flex-direction: row;
}
.edit-event-image-wrapper {
    display: flex;
    flex-direction: column;
}
.edit-event-tag-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}
.edit-event-title {
    font-size: 30px;
    color: #3C4858;
}
.edit-event-image {
    object-fit: cover;
    height: 240px;
    width: 240px;
}
.edit-event-location {
    display: flex;
    margin-top: 14px;
}
.edit-event-details {
    width: 100%;
    padding-left: 9px;
    text-align: start;
    line-height: 15px;
    font-size: 14px;
    color: #3C4858;
}
.edit-event-add {
    background-color: #5DC2C9;
    color: white;
    padding: 5px;
    height: 40px;
    width: 200px;
    font-size: 16px;
    text-align: center;
}
.edit-event-remove {
    background-color: darkred;
    color: white;
    padding: 5px;
    height: 20px;
    width: 60px;
    font-size: 12px;
    text-align: center;
}
.edit-event-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.new-event-suggestions {
    position: absolute;
    background-color: white;
    width: 550px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 5px;

    .suggestion-entry {
        font-size: 14px;
        line-height: 20px;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
    }
    .suggestion-entry:hover {
        background-color: $primaryColor;
        color: white;
        cursor: pointer;
    }
}











