.marker {
    width: 40px;
    height: 40px;
    left: 20px;
    top: 20px;

    border: 5px solid #f44336;
    border-radius: 40px;
    background-color: white;
    text-align: center;
    color: #3f51b5;
    font-size: 10px;
    padding: 4px;
}
.clicked-marker {
    background-color: red;
}