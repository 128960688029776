.days-screen {
  display: flex;
  flex-direction: row;
  //overflow-x: scroll;
  padding: 20px;
  padding-bottom: 40px;
  padding-top: 40px;
  scroll-snap-type: x mandatory;
  .days-column {
    scroll-snap-align: center;
    min-width: 380px;
  }
  .new-day {
    margin-top: 20px;
    min-width: 380px;
    max-width: 380px;
    height: 58px;
    //position: sticky;
    //top: 0px;
  }
}
.day-plan {
  .days-fixed-header {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed;
    background: white;
    overflow-x: scroll;
    border-bottom: 1px solid lightgrey;
    z-index: 3;
    display: none;
    width: 100%;
    .day-card-header {
      width: 380px;
      margin-right: 10px;
    }
  }
  .days-fixed-header.show {
    display: flex;
  }
}