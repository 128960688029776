.add-day-btn {
    padding: 5px;
    height: 36px;
    width: 110px;
    background-color: #CEECEE;
    font-size: 16px;
    color: #7FCFD4;
    border-radius: 5px !important;
}
.day-card-header {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: sticky;
        top: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: white;
        z-index: 3;
        border-bottom: 1px solid lightgrey;
}
.day-card {
    margin-top: 8px;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.day-expanded {
    height: 100%;
}
.day-content-wrapper {
    height: 100%;
    border-right: 1px solid lightgray;
}
.day-collapsed {
    height: 40px;
}
.day-name {
    font-size: 16px;
    line-height: 23px;
    color: #3C4858;
    display: flex;
    justify-content: center;
    flex: 1;
    text-align: center;
}
.day-event-count {
    color: #9597A1;
    font-size: 14px;
    display: flex;
    flex: 1;
    justify-content: flex-end;

}
.day-icon {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.day-content {
    display: flex;
    margin-top: 7px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}
.day-empty {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #898b8b;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}
.day-dragged {
    background-color: rgba(93, 194, 201, 0.16);
    height: 100%;
}
.day-distance {
    color: #6b6d6d;
    align-items: center;
    display: flex;
    justify-content: center;
}
.day-distance > span {
margin-right: 20px
}
.day-distance-icon {
margin-right: 5px
}

.day-plan {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
    padding-bottom: 50px;
    scroll-snap-type: x mandatory;
}
.day-plan .title {
    margin-top: 15px;
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: sticky;
    left: 0;
}