.tag {
    margin: 3px;
    height: 16px;
    width: 64px;
    font-size: 9px;
    font-weight: bold;
    line-height: 10px;
    color: white;
    text-align: center;
    background: #BAB6B5;
}

.arrow-icon {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background: #5DC2C9;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-bottom: 8px;
    margin-right: 4px;
}
.tag-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.arrow-icon:hover {
    background: #48969f;
}
.time-tag {
     background: #BAB6B5;
}
.time-tag:active {
    background-color: #D0A494 !important;
}
.time-tag.bp3-tag.bp3-active {
    background-color: #D0A494 !important;
}
/* Tag category colors */
.Landmark.bp3-tag.bp3-active {
    background-color: #5B9DFC !important;
}
.Adventure.bp3-tag.bp3-active {
     background-color: #655BCD !important;
 }
.Shopping.bp3-tag.bp3-active {
    background-color: #FC6D5B !important;
}
.Food.bp3-tag.bp3-active {
    background-color: #76ffa8 !important;
}
.Hotel.bp3-tag.bp3-active {
    background-color: #fcb552 !important;
}
.Transport.bp3-tag.bp3-active {
    background-color: #fc2b7e !important;
}
.Free-time.bp3-tag.bp3-active {
    background-color: #48969f !important;
}
.Tour.bp3-tag.bp3-active {
    background-color: #90682f !important;
}
.Museum.bp3-tag.bp3-active {
    background-color: #de1732 !important;
}
.Night.bp3-tag.bp3-active {
    background-color: #4132b1 !important;
}
