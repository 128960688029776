@import "./variables";

// EVERY TSX file should have its own high-level class here

.landing-board {
  display: flex;
  flex-direction: column;
  width: 100%;
  .landing-main {
    padding: 100px 50px 50px 50px;
    background: whitesmoke;
  }
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $pinkColor;
    color: whitesmoke;
    width: 100%;
    text-align: center;
    padding: 30px;
    font-size: 14px;
    display: none;
  }
  .all-boards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;

    .landing-board-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .trip-photos {
        width: 200px;
        img {
          object-fit: cover;
          height: 100px;
          width: 100px;
        }
      }

    }
    h3 {
      padding-left: 10px;
    }
    .board-section {
      margin: 10px;
      position: relative;
      cursor: pointer;
      background: white;
      padding: 20px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
      .board-hover {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        padding: 15px;
        -moz-transition: all 0.4s ease-in-out 0s;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -ms-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
      }
    }
    .board-section:hover {
      .board-hover {
        opacity: 1;
      }
    }
  }
}

.landing-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  position: fixed;
  background: white;
  z-index: 10;
  .landing-info {
    display: flex;
    flex-direction: column;
  }
  .landing-title {
    font-size: 60px;
    color: #5DC2C9;
    line-height: 70px;
  }
  .landing-location {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .landing-location-input {
    width: 500px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  .landing-location-input > input {
    font-size: 16px;
    height: 100%;
    padding-left: 52px !important;
  }
  .landing-location-input > span {
    margin-left: 12px !important;
    margin-top: 12px !important;
  }
  .inspiration-link {
    height: 40px;
    width: 500px;
  }
}
.landing {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  padding-top: 65px;
  .landing-header {
    position: relative;
  }
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $pinkColor;
    color: whitesmoke;
    width: 100%;
    text-align: center;
    padding: 30px;
    font-size: 14px;
    display: none;
  }
  .landing-tagline {
    font-size: 30px;
    line-height: 35px;
    color: #5DC2C9;
  }
  .landing-main {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    align-self: center;
  }
  .landing-left {
    display: flex;
    flex-direction: column;
    //flex: 1;
    width: 50%;
  }
  .landing-right {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
  }
  .toodle-screenshot {
    height: 500px;
    margin-right: -100px;
    object-fit: fit;
    margin-left: 40px;
  }
  .landing-description {
    font-size: 34px;
    line-height: 50px;
    display: inline;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 600px;
  }
  .landing-rotate {
    display: inline;
    text-indent: 10px;
    color: #5DC2C9;
    font-weight: 900;
  }
  .react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
  }

  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .landing-location {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 40px;
  }
  .all-boards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;

    .landing-board-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .trip-photos {
        width: 210px;
        img {
          object-fit: cover;
          height: 100px;
          width: 100px;
        }
      }

    }
    h3 {
      padding-left: 10px;
    }
    .board-section {
      margin: 10px;
      position: relative;
      cursor: pointer;
      background: white;
      padding: 20px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
      .board-hover {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        font-weight: 500;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        padding: 15px;
        border-radius: 12px;
        -moz-transition: all 0.4s ease-in-out 0s;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -ms-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
      }
    }
    .board-section:hover {
      .board-hover {
        opacity: 1;
      }
    }
  }
  .landing-location-input {
    width: 500px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  .landing-location-input > input {
    font-size: 16px;
    height: 100%;
    padding-left: 52px !important;
  }
  .landing-location-input > span {
    margin-left: 18px !important;
    margin-top: 22px !important;
  }
  .inspiration-link {
    height: 60px;
    width: 600px;
  }
  .go-btn {
    width: 100px;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
  }
  .howto-title {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }
  .landing-howto {
    font-size: 20px;
    color: #5c7080;
    line-height: 30px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .landing-step {
    //margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .landing-step > span {
    margin-right: 20px;
  }
  .landing-preview-box {
    background: white;
    width: 340px;
    height: 160px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: 0.569857px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 1px 5.46px 5.46px rgba(0, 0, 0, 0.09);
    border-radius: 4.89655px;

  }
  .landing-trips {
    margin-top: 50px;
    opacity: 1;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 50px;
  }
  .trips-col {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile-pic {
  }
  .profile-pic > img{
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
  .box-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .box-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 10px;

  }
  .box-center {
    padding: 10px;
    justify-content: space-around;
  }
  .box-location {
    font-weight: 900;
    font-size: 22px;
    line-height: 18px;

  }
  .box-owner {
    font-size: 14px;
    line-height: 13px;

    display: flex;
    align-items: center;
    color: #9597A1;
    margin-top: 10px;
  }
  .box-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 10px;
  }
  .box-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    margin-top: 20px;

  }
  .box-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: #eeeffe;
    border-radius: 17.5px;
    color: #5458F7;
    text-align: center;
    margin-right: 6px;
    font-size: 20px;
  }
  .box-number > div:last-child {
    font-size: 14px;
  }
  .box-btn {
    width: 130px;
    height: 22px;

    background: $complementColor;
    border-radius: 30px;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .box-btn:hover {
    background: #447392;
  }
  .trips-title {
    font-size: 32px;
    font-weight: 800;
    line-height: 37px;
    align-items: center;
    color: #5DC2C9;
    margin-bottom: 20px;
  }
  .all-trips {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .dropdown-options {
    width: 500px;
  }

  @media (max-width: 1320px) {
    .landing-main {
      flex-direction: column;
      align-items: flex-start;
    }
    .landing-right {
      margin-top: 50px;
    }
    .landing-left {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    padding: 20px;
    .banner {
      display: none;
    }
    .landing-main {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .landing-description {
        font-size: 24px;
      }
      .inspiration-link {
        width: 300px;
      }
      .landing-location-input {
        width: 300px;
      }
      .landing-right {
        margin-left: 0px;
      }
      .dropdown-options {
        width: 300px;
      }
      .all-boards {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
      }
    }

  }

}
.image-gallery {
  width: 400px;
}
.choose-type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  button {
    height: 40px;
    font-weight: 600;
    //padding: 20px;
    margin-right: 10px;
    margin-top: 10px;
  }
  .location-name {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
    color: $blueColor;
  }
  .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    img {
      height: 150px;
      margin-bottom: 20px;
    }
  }
}
.location-name-entry {
  font-size: 16px;
  width: 488px;
  height: 40px;
  padding-left: 52px;
  display: flex;
  align-items: center;
}

.google-button {
  height: 50px;
  width: 240px;
  background-color: #4285f4;
  border: none;
  color: #fff;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;

  .abcRioButtonIcon {
    background-color: #fff;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    float: left;
    color: #fff;
  }
  .abcRioButtonContents {
    font-family: Roboto,arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .21px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: top;
  }
}
.google-button:hover {
  background-color: #4285f4;
  -webkit-box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
  box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
}

.facebook-button {
  cursor: pointer;
  background-color: #4267b2;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width:240px;

  .fb-logo {
    height: 24px;
    width: 24px;
    margin: 8px;
  }
  .content {
    font-size: 16px;
    margin-right: 12px;
    border: none;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: .25px;
    overflow: hidden;
    text-align: center;
    text-overflow: clip;
    white-space: nowrap;
  }
}

.main-board {
  display: flex;
  flex-direction: row;
  
  .show-map {
    display: none;
  }
  .events-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .trip-add-day {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      height: 150px;
      width: 150px;
      background-color: #CEECEE;
      font-size: 16px;
      color: #7FCFD4;
      border-radius: 12px;
      margin: 20px;
      cursor: pointer;
    }
    .trip-add-day:hover {
      background: #48969f;
    }
  }
  .events-panel {
    height: 91vh;
    overflow: scroll;
    background: #f5f5f5;
    flex-grow: 1;

    h2 {
      margin-left: 20px;
      margin-top: 20px;
    }
  }

  .events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .empty-day {
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: darkgrey;

      span {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .map-area {
    min-width: 500px;
    margin-bottom: 0px;
    animation: expand .5s ease-in-out;
  }
  .board-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 15px;
      font-size: 26px;
      font-weight: 600;
      line-height: 30px;

    }
  }
  .trip-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 15px;
      font-size: 26px;
      font-weight: 600;
      line-height: 30px;
    }
  }
  .go-on-trip {
    border: solid 1px $pinkColor;
    padding: 8px 5px 8px 5px;
    margin-right: 35px;
    font-size: 14px;
    border-radius: 5px;
    color: $pinkColor;
    cursor: pointer;
  }
  .go-on-trip:hover {
    background: $pinkColor;
    color: white;
  }
  .board-map {
    position: relative;
    min-width: 500px;
  }
  .map-overlay {
    position: absolute;
    top: 0;
    left: 10px;
  }
}

.board-event {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  //border: solid lightgray 1px;
  width: 150px;
  margin-right: 12px;
  margin-top: 12px;
  background: white;

  .event-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 10px;
    font-weight: 500;
    flex-grow: 1;

    .event-name {
      font-size: 14px;
      font-weight: 600;
    }
    .move-icon {
      cursor: pointer;
    }
    .move-icon:hover {
      svg {
        fill: black;
      }
    }
    .event-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
    }
    .tags {
      display: flex;
      flex-direction: row;
      .name-tag {
        border-radius: 10px;
        height: 20px;
        font-size: 10px;
        background: rgba(101, 91, 205, 0.48);
        margin-right: 5px;
        margin-top: 10px;
        padding: 4px 10px;
      }
    }
  }
  .event-image-wrapper {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 150px;
    height: 150px;

    .event-image {
      object-fit: cover;
      height: 150px;
      width: 150px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

    }
    .description {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      //right: 0;
      //bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 15px;
      -moz-transition: all 0.4s ease-in-out 0s;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -ms-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  .event-image-wrapper:hover .description,
  .event-image-wrapper.active .description {
    opacity: 1;

  }

}
.company {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .company-logo {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .company-name {
    font-size: 28px;
  }
}
.book {
  width: 100%;
  height: 100%;
  .hide {
    display: none;
  }
  .subtitle.top {
    display: none;
  }
  .landing-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 100%;
    padding: 10px;
    position: fixed;
    background: whitesmoke;
    z-index: 10;
  }
  .book-trip {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    .trip-content {
      padding-top: 60px;
      width: 100vw;
      .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .narrow {
          max-width: 1000px;
          margin-left: 40px;
          margin-right: 40px;
        }
        .narrow > * {
          margin-top: 40px;
        }
      }
      .text-section {
        font-size: 18px;
        line-height: 28px;
      }
      .summary {
        display: flex;
        justify-content: space-between;
        align-items: start;
      }
      .cities {
        display: flex;
        flex-direction: column;
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .item > div {
        font-size: 18px;
        margin-top: 20px;
        line-height: 26px;
      }
      .item > span {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: darkgrey;
        min-width: 90px;
        svg {
          margin-right: 10px;
        }
      }
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px $pinkColor;
        padding: 8px 5px 8px 5px;
        font-size: 20px;
        border-radius: 5px;
        color: $pinkColor;
        cursor: pointer;
      }
      .button:hover {
        background: $pinkColor;
        color: white;
      }
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .plan {
        width: 100%;
      }
      .plan-days {
        padding: 20px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
      }
      .date-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px $complementColor;
        padding: 20px 10px;
        font-size: 20px;
        border-radius: 5px;
        color: $complementColor;
        cursor: pointer;
        margin-top: 10px;
      }
      .date-option:hover, .date-option-active {
        background: $complementColor;
        color: white;
      }
      .dates {
        margin-bottom: 80px;
        width: 100%;
      }
      .dates .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .organizers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 40px 10px;
      }
      .host {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: 220px;
        width: 450px;
        max-width: 450px;
        flex: 1 0 auto;
        border-radius: 16px;
        margin: 8px 8px;
        padding: 32px;
        position: relative;
        .picture {
          height: 150px;
          width: 150px;
          border-radius: 75px;
        }
        .name {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          margin-left: 30px;
        }
        .bottom-right {
          display: flex;
          position: absolute;
          justify-content: flex-end;
          align-items: center;
          bottom: 10px;
          right: 10px;
        }
        .verified {
          margin-right: 10px;
        }
        .instagram-logo {
          height: 40px;
          width: 40px;
          background: url("/icons/instagram-grey.svg");
          background-size: 40px 40px;
        }
        .instagram-logo:hover {
          background: url("/icons/instagram.svg");
        }
      }
      .booking-form {
        display: flex;
        flex-direction: column;
        .row {
          display: flex;
          flex-direction: row;
        }
        .bp3-input-group {
          margin: 10px 30px;

        }
      }
      .location-thumbnail {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: 300px;
        width: 200px;
        flex: 1 0 auto;
        border-radius: 16px;
        margin: 8px 8px;
        position: relative;
        cursor: pointer;
        .emoji {
          opacity: 0;
          position: absolute;
          border-radius: 16px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          -moz-transition: all 0.4s ease-in-out 0s;
          -webkit-transition: all 0.4s ease-in-out 0s;
          -ms-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s;
          font-size: 40px;
        }
      }
      .location-thumbnail > img {
        height: 250px;
        width: 200px;
        object-fit: cover;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      .trip-name {
        margin-top: 15px;
        font-size: 20px;
        font-weigth: 600;
      }

      .location-thumbnail:hover .emoji,
      .location-thumbnail.active .emoji {
        opacity: 1;

      }

      .qas {
        .qa {
          margin-top: 25px;
          .q {
            font-size: 24px;
            font-weight: 400;
            line-height: 40px;
          }
          .a {
            font-size: 18px;
            line-height: 24px;

          }
        }
      }

      }
    .cta-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      line-height: 30px;

      .book-now {
        min-height: 60px;
        padding: 10px;
        margin-top: 20px;
        text-align: center;
      }
    }
    .reservation-page {
      width: 100%;
      max-width: 600px;
      .input-text {
        input[type="text"] {
          box-sizing: border-box;
          width: 100%;
          height: calc(3em + 2px);
          margin: 0 0 1em;
          padding: 1em;
          border: 1px solid #ccc;
          border-radius: 1.5em;
          background: #fff;
          resize: none;
          outline: none;
        }
        input[type="text"][required]:focus {
          border-color: $complementColor;
        }
        input[type="text"][required]:focus + label[placeholder]:before {
          color: $complementColor;
        }
        input[type="text"][required]:focus + label[placeholder]:before,
        input[type="text"][required]:valid + label[placeholder]:before {
          transition-duration: 0.2s;
          -webkit-transform: translate(0, -1.5em) scale(0.9, 0.9);
          transform: translate(0, -1.5em) scale(0.9, 0.9);
        }
        input[type="text"][required]:invalid + label[placeholder][alt]:before {
          content: attr(alt);
        }
        input[type="text"][required] + label[placeholder] {
          display: block;
          pointer-events: none;
          line-height: 1.25em;
          margin-top: calc(-3em - 2px);
          margin-bottom: calc((3em - 1em) + 2px);
        }
        input[type="text"][required] + label[placeholder]:before {
          content: attr(placeholder);
          display: inline-block;
          margin: 0 calc(1em + 2px);
          padding: 0 2px;
          color: #898989;
          white-space: nowrap;
          transition: 0.3s ease-in-out;
          background-image: linear-gradient(to bottom, #fff, #fff);
          background-size: 100% 5px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  body {
    .book .subtitle.top {
      display: block;
      margin-top: 5px !important;
    }
    h2.bp3-heading, .bp3-running-text h2 {
      font-size: 22px;
    }
    .company .company-name {
      font-size: 18px;
    }
    .landing-title > img {
      width: 60px;
    }
    .book .nav-user-account {
      display: none;
    }
    .book .book-trip .trip-content {
      .summary {
        flex-direction: column;
        .item {
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          margin-top: 20px;
          .item-icon {
            margin-top: 20px;
          }
          div {
            margin-left: 20px;
          }
        }
      }
      .text-section {
        font-size: 14px;
        line-height: 20px;
      }
      .host {
        height: 180px;
        width: 300px;
        max-width: 300px;
        .picture {
          height: 80px;
          width: 80px;
          border-radius: 40px;
        }
        .name {
          font-size: 18px;
          font-weight: 600;
          line-height: 20px;
          margin-left: 20px;
        }
        .instagram-logo {
          height: 30px;
          width: 30px;
          background-size: 30px 30px;
        }
      }
      .organizers {
        flex-direction: column;
      }
      .dates {
        .title {
          display: none;
        }
        .date-option {
          justify-content: center;
        }
        .date-option > span:nth-of-type(n+2) {
          display: none;
        }
      }
      .plan-days {
        padding: 0px;
      }
    }
  }
}

.toggle-button {
  border: solid 1px $pinkColor;
  margin-right: 35px;
  font-size: 14px;
  min-width: 400px;
  border-radius: 5px;
  color: $pinkColor;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.toggle-element {
  width: 50%;
  height: 100%;
  padding: 8px 5px 8px 5px;
  text-align: center;
}
.toggle-element.clicked {
  background: $pinkColor;
  color: white;
}
.toggle-element:not(.clicked):hover {
  background: rgba(252, 109, 91, 0.37);
  color: white;
}

.events-section {
  padding: 20px;
  border: gainsboro 1px solid;
  margin: 10px;
  border-radius: 12px;
  animation: expand .5s ease-in-out;
  .event-section-name {
    width: 100%;
    position: sticky;
    top: 0px;
    background: #f5f5f5;
    z-index: 3;
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 0.9;
  }
}
@keyframes expand {
  from {
    //transform: scale(0);
    opacity: 0;
    background: #5470B0;
  }
}
.map-pin-wrapper {
  .tag-list {
    transform: translate(0, 30px);
  }
}
.map-pin {
  height: 30px;
  width: 30px;
  border-radius: 50% 50% 50% 0;
  display: inline-block;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  transform: rotate(-45deg);
  z-index: 0;
  opacity: 0.8;
  .day-number {
    position: absolute;
    font-size: 14px;
    color: white;
    font-weight: 600;
    transform: rotate(45deg);
    margin: 8px 10px 5px 10px;
  }
}
.map-pin-wrapper:hover {
  .map-pin {
    opacity: 1;
  }
}

.event-map-popup {
  padding: 6px;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid darkgrey;
}

.account-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  .account-main {
    padding: 100px 50px 50px 50px;
    display: flex;
    flex-direction: column;
    .section {
      .title {
        font-size: 24px;
        line-height: 36px;
        margin-top: 30px;
        font-weight: 600;
      }
      .links {
        .link-element {
          font-size: 20px;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .board-event {
    width: 300px;
    flex-direction: row;
    margin-right: 0px;
    .event-image-wrapper {
      height: 71px;
      width: 71px;
      .event-image {
        height: 71px;
        width: 71px;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 0px;
      }
    }
  }
  .toggle-button {
    min-width: 250px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .nav-actions {
    .vl {
      display: none;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
    padding: 10px;
    border-top: solid 1px lightgrey;
  }
  .main-board {
    flex-direction: column;
    .events-panel {
      padding-bottom: 100px;
    }
    .show-map {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 50px;
      height: 50px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 10px;
      z-index: 100;
      margin-bottom: 10px;
    }
    .show-map.selected {
      background: $pinkColor;
      color: white;
      svg {
        fill: white;
      }
    }
  }
  .hide {
    display: none;
  }
  .new-event-card {
    padding-left: 5px;
    padding-right: 5px;
  }
  .inspiration-link {
    min-width: 300px;
    width: 300px;
  }
  .new-event-suggestions {
    width: 300px;
  }
  .edit-event-dialog {
    .edit-event-title {
      font-size: 18px;
    }
    .edit-event-middle {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .edit-event-details {
      padding-left: 0px;
      padding-bottom: 10px;
    }
    .edit-event-image-wrapper {
      justify-content: center;
      align-items: center;
    }
    .edit-event-image {
      width: 100px;
      height: 100px;
    }
  }
  .mobile-hide {
    display: none;
  }
  .mobile-nav-item {
    display: flex;
    align-items: center;
  }
}