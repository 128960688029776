.full-wishlist {
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .wishlist-cols {
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow: scroll;
    //margin-left: 20px;
    margin-right: 20px;
  }
  .filter-events {
    margin-right: 20px;
    padding-left: 5px;

    .tag-name {
      font-size: 16px;
    }
  }
}