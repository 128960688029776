@import "./variables";

.main-screen {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    height: 100vh;
}
.user-account-header {
    display: flex;
    flex-direction: row;
}
.user-account-header > * {
    margin-right: 8px;
}
.wishlist {
    /*flex-basis: 50%;*/
}

.navbar-style {
    box-shadow: none !important;
    border-bottom: 1px solid #e4e4e4 !important;
    height: 60px;
    display: flex;
    align-items: center;

    .trip-name {
        font-size: 18px;
        line-height: 18px;
    }
}
.main-navbar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.navbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    margin-bottom: 1px;
}
.nav-item {
    display: flex;
    justify-content: center;
    height: 100%;
}
.mobile-nav-item {
    display: none;
}
.logo {
    font-size: 44px;
    color: $primaryColor;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $complementColor;
}
.nav-item:first-child {
    justify-content: flex-start;
}
.nav-item:last-child {
    justify-content: flex-start;
    flex: 1;
}
.nav-user-account {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}
.navbar-button {
    height: 100% !important;
    vertical-align: middle !important;
    box-sizing: border-box !important;
    border: 0px none;
    border-bottom: 2px transparent !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    background: white;
}
.navbar-button:hover {
    border-bottom: 2px solid $pinkColor !important;
}
.navbar-spinner {
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-logo-group {
    color: #5DC2C9;
    justify-content: center;
    font-size: 20px;
}
.navbar-account {
    width: 48px;
    height: 48px;
    border-radius: 24px;
}
.navbar-account:hover {
    border: 3px solid $pinkColor !important;
}
.main-logo {
}
.main-app {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    .hide {
      display: none;
    }
}
.map-area {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    min-height: 300px;
    min-width: 200px;
    width: 100%;
    height: 91vh;
    margin-bottom: 40px;
}
.main-tabs {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
}
.main-workspace {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.ws-active {
    display: flex;
    flex-direction: row;
}
.ws-wishlist {
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 5px;
    padding-left: 42px;
    padding-right: 20px;
    overflow: scroll;
    height: 95vh;
}
.ws-droppable {
    flex: 1;
}
.ws-dragged {
    background-color: rgba(93, 194, 201, 0.16);
}
.ws-days {
    width: 400px;
    padding-right: 10px;
    padding-left: 5px;
    overflow: scroll;
    height: 95vh;
}
.section-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #3C4858;

}
.section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}
.ws-days-footer {
    display: flex;
    justify-content: flex-end;
}
.ws-empty {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #898b8b;

}
.ws-empty-desc {
    font-style: italic;
    margin-bottom: 20px;
}
.ws-empty-instructions > div {
    margin-top: 15px;
}
pre code {
    overflow: auto;
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 10px;
    font-size: 12px;
}
pre {
    font-size: 14px;
}

/* ANIMATION */
.flyout {
    position: absolute;
    margin-left: 10px;
    margin-top: 60px;
    font-size: 40px;
    /* transition-timing-function: ease-in; */
    -webkit-animation: flyout 2s 0.1s forwards;
    animation: flyout 2s 0.1s forwards;
}
.slide-right {
    position: absolute;
    margin-left: -100px;
    margin-top: 30px;
    font-size: 40px;
    transition-timing-function: ease-in;
    -webkit-animation: slide-right 10s 4s forwards;
    animation: slide-right 10s 4s forwards;
}
/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes flyout {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
    100% {
        -webkit-transform: translateY(-60px) translateX(2000px);
        transform: translateY(-60px) translateX(2000px);
    }
}
@keyframes flyout {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
    100% {
        -webkit-transform: translateY(-60px) translateX(2000px);
        transform: translateY(-60px) translateX(2000px);
    }
}

@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(100vw + 100px));
        transform: translateX(calc(100vw + 100px));
    }
}
@keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(100vw + 100px));
        transform: translateX(calc(100vw + 100px));
    }
}
