.login-dialog {
    padding: 20px 100px 20px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-dialog > * {
    margin-top: 40px;
}
.login-desc {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #3C4858;
}
.login-input {
    width: 100%;
}
.submit-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%
}
.submit-row>span {
    margin-right: 7px;
}
.login-btn {
    height: 40px;
    width: 100px;
    background: #5DC2C9 !important;
    color: white;
    margin-right: 10px;
    text-align: center;
}
.login-error {
    width: 100%;
    overflow: auto;
}

.clone-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.clone-button {
    margin-top: 20px;
}